import React from 'react';
import { Grid, Loader, Message, Breadcrumb } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import { useReactiveState } from '../../../utils/hooks/useReactiveState';
import Tile from '../../../components/Tile/Tile';
import BoxScoreTable from './TeamBoxScoreTable';
import { GET_TEAM_BOX_SCORE } from './getTeamBoxScore';
import { mf_Teams } from '../../../apollo';

const BoxScore = () => {
  const { loading, error, data } = useQuery(GET_TEAM_BOX_SCORE);
  const [teamId] = useReactiveState(mf_Teams);

  const chartData = data?.boxScores?.edges?.map(({ node }) => node);

  const hasData = !!chartData?.length;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16} stretched>
          <Tile full>
            <Tile.Header>
              <Breadcrumb size="huge">
                <Breadcrumb.Section>Team</Breadcrumb.Section>
                <Breadcrumb.Divider />
                <Breadcrumb.Section active>Box Score</Breadcrumb.Section>
              </Breadcrumb>
              <h1>
                Box Score
                {loading && <Loader active inline size="mini" />}
              </h1>
            </Tile.Header>
            <Tile.Body>
              {error && (
                <Message negative>
                  There has been an error. Please contact support.
                </Message>
              )}
              {hasData && <BoxScoreTable data={chartData} teamId={teamId} />}
              {!loading && !hasData && (
                <p>No data available for selected team</p>
              )}
            </Tile.Body>
          </Tile>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default BoxScore;
