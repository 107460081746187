import React from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { uniqBy, sortBy } from 'lodash';
import { useParams } from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react';
import { Dropdown } from '@statsbomb/kitbag-components';
import useQueryString from '../../../utils/hooks/useQueryString';
import { pf_Drive, mf_Games } from '../../../apollo';
import PageFilters from '../../../components/PageFilters/PageFilters';
import Tile from '../../../components/Tile/Tile';
import { DROPDOWN_SPECIAL_OPTIONS } from '../../../components/DropdownFilter/DropdownFilter.constants';
import { GET_SNAP_EVENTS } from './getSnapEvents';
import { GET_DRIVE_SNAPS } from './getDriveSnaps';
import SnapList from './SnapList';
import EventTiles from './EventTiles';

const GameSnapFormations = () => {
  const { drives } = useParams();
  const driveId = useReactiveVar(pf_Drive);
  const gameId = useReactiveVar(mf_Games);
  const [selectedEvent, setSelectedEvent] = useQueryString('event', '');
  const [selectedOffensePlayerId, setSelectedOffensePlayerId] = useQueryString(
    'offense',
    0
  );
  const [selectedDefensePlayerId, setSelectedDefensePlayerId] = useQueryString(
    'defense',
    0
  );
  const { error, loading, data } = useQuery(GET_SNAP_EVENTS);
  const { data: driveInfo } = useQuery(GET_DRIVE_SNAPS);

  const formattedData = data?.snapEvents?.edges.map((m) => m.node);

  const handleSetOffensePlayerId = (selectedOption) => {
    const { value } = selectedOption;
    setSelectedOffensePlayerId(value);
  };

  const handleSetDefensePlayerId = (selectedOption) => {
    const { value } = selectedOption;
    setSelectedDefensePlayerId(value);
  };

  const noneDropDownOption = { value: 0, label: 'None' };
  const playersData = function (d, isOffense = true) {
    const offenseTeamId =
      d?.snapEvents?.edges?.length > 0
        ? d.snapEvents.edges[0].node.play.offenseTeam?.id
        : 0;
    const ffsLoc = d?.snapEvents?.edges?.map((z) => z.node.freezeFrames || []);
    // eslint-disable-next-line prefer-spread
    const flattenedFFs = [].concat.apply([], ffsLoc);
    const teamFFs = isOffense
      ? flattenedFFs.filter((f) => f.team?.id === offenseTeamId)
      : flattenedFFs.filter((f) => f.team?.id !== offenseTeamId);
    const uniqPlayers = sortBy(
      uniqBy(
        teamFFs.filter((f) => f.player),
        (f) => f.player.id
      ),
      'player.name'
    );
    const playersDropDownData = uniqPlayers.map((m) => ({
      value: parseInt(m.player.id, 10),
      label: `${m.player.name} #${m.player.jerseyNumber}`,
    }));
    playersDropDownData.push(noneDropDownOption);
    return playersDropDownData;
  };

  const onDriveChange = () => {
    setSelectedOffensePlayerId(0);
    setSelectedDefensePlayerId(0);
    setSelectedEvent(null);
  };

  const pageFilters = (
    <PageFilters
      displayPassFilter={false}
      onDriveChange={onDriveChange}
      inline
      wider
      optionalAllDrives
    />
  );

  const tileList =
    !DROPDOWN_SPECIAL_OPTIONS.includes(drives) &&
    driveInfo &&
    driveInfo?.plays?.edges?.length ? (
      <Tile.AccordionBody>
        <SnapList
          driveInfo={driveInfo}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          displayedEvents={data?.snapEvents?.edges}
        />
      </Tile.AccordionBody>
    ) : (
      <></>
    );

  const playerFilters =
    !!driveId && !!gameId && data && data?.snapEvents?.edges?.length ? (
      <Tile.AccordionBody>
        <Dropdown
          id="highlight-offense-dropdown"
          options={playersData(data)}
          label="Highlight Offense"
          onChange={(selectedOption) =>
            handleSetOffensePlayerId(selectedOption)
          }
          value={playersData(data).find(
            (f) => f.value === selectedOffensePlayerId
          )}
          isLoading={loading}
          menuPosition="static"
        />

        <Dropdown
          id="highlight-defense-dropdown"
          options={playersData(data, false)}
          label="Highlight Defense"
          onChange={(selectedOption) =>
            handleSetDefensePlayerId(selectedOption)
          }
          value={playersData(data, false).find(
            (f) => f.value === selectedDefensePlayerId
          )}
          isLoading={loading}
          menuPosition="static"
        />
      </Tile.AccordionBody>
    ) : (
      <></>
    );

  const breadcrumb = (
    <Breadcrumb size="huge">
      <Breadcrumb.Section>Game</Breadcrumb.Section>
      <Breadcrumb.Divider />
      <Breadcrumb.Section active>Snap Formations</Breadcrumb.Section>
    </Breadcrumb>
  );

  return (
    <EventTiles
      formattedData={formattedData}
      isError={!!error}
      isLoading={loading}
      selectedEvent={selectedEvent}
      setSelectedEvent={setSelectedEvent}
      visTitle="Snap Formations"
      breadcrumb={breadcrumb}
      pageFilters={pageFilters}
      tileList={tileList}
      playerFilters={playerFilters}
      selectedOffensePlayerId={selectedOffensePlayerId}
      selectedDefensePlayerId={selectedDefensePlayerId}
    />
  );
};

export default GameSnapFormations;
