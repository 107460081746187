import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonIcon } from '@statsbomb/kitbag-components';
import {
  SummaryTile,
  SummaryTileBody,
  SummaryTileHeader,
} from '../../../components/Tile/TileSummary.styles';
import {
  PlayFinderFilterConfigBg,
  PlayFinderFilterConfigLayout,
  PlayFinderFilterConfigWrapper,
  PlayFinderTileFooter,
} from './PlayFinderFiltersConfig.styles';
import WizardAccordion from '../PlayFinderWizard/WizardAccordion/WizardAccordion';
import { getPlayFiltersWidget } from './PlayFinderWidgets/PlayFinderWidgets';
import { getWidgetValue } from './PlayFinderFiltersConfig.dataManipulation';
import { PLAY_FINDER_WIDGET_BLACKLIST } from './PlayFinderWidgets/PlayFinderWidgets.constants';
import { updateFilterById } from '../PlayFinderFilters/PlayFinderFilters.dataManipulation';
import TreeSearch from '../../../components/TreeSearch/TreeSearch';

const PlayFinderFiltersConfig = forwardRef(
  ({ playFinderFilterTree, onAddFilter }, ref) => {
    const [filterConfigOpen, setFilterConfigOpen] = useState(false);
    const [selectedParentIndex, setSelectedParentIndex] = useState(0);
    const [filterTree, setFilterTree] = useState(playFinderFilterTree);
    const treeSearchRef = useRef();

    const toggleFilterOpen = () => {
      if (!filterConfigOpen) {
        setFilterTree(playFinderFilterTree);
        treeSearchRef.current.clearInput();
      }
      setFilterConfigOpen(!filterConfigOpen);
    };

    useImperativeHandle(ref, () => ({
      toggle() {
        toggleFilterOpen();
      },
    }));

    const onWidgetCallback = (filterId, value) => {
      setFilterTree(updateFilterById(filterTree, filterId, value));
    };

    return (
      <>
        <PlayFinderFilterConfigWrapper
          $left={filterConfigOpen ? '0' : '-768px'}
        >
          <SummaryTile>
            <SummaryTileHeader>
              <div>
                <h3>Add/Remove Filter</h3>
              </div>
              <div>
                <ButtonIcon
                  onClick={toggleFilterOpen}
                  variant="ghost"
                  shape="pill"
                  size="small"
                  icon="Close"
                  title="Cancel"
                />
              </div>
            </SummaryTileHeader>
            <SummaryTileBody $overflowY="hidden">
              <PlayFinderFilterConfigLayout>
                <TreeSearch
                  initialTree={playFinderFilterTree}
                  onFilter={setFilterTree}
                  ref={treeSearchRef}
                />
                <div className="row">
                  <div>
                    {filterTree?.map((filter, i) => (
                      <Button
                        id={`${filter.label}`}
                        key={filter.label}
                        variant={
                          i === selectedParentIndex ? 'secondary' : 'ghost'
                        }
                        onClick={() => setSelectedParentIndex(i)}
                      >
                        {filter.label}
                      </Button>
                    ))}
                  </div>
                  <div>
                    <h3>{filterTree[selectedParentIndex]?.label}</h3>
                    {filterTree[selectedParentIndex]?.children?.map((child) => {
                      const options = child.children
                        .filter(
                          (filter) =>
                            !PLAY_FINDER_WIDGET_BLACKLIST.includes(
                              filter.filterId
                            )
                        )
                        .map((filter) => (
                          <WizardAccordion
                            title={`${filter.label}${getWidgetValue(
                              filter.units,
                              filter.widgetType,
                              filter.widgetArgs
                            )}`}
                          >
                            {getPlayFiltersWidget(
                              filter.filterId,
                              filter.label,
                              filter.widgetType,
                              filter.widgetArgs,
                              onWidgetCallback
                            )}
                          </WizardAccordion>
                        ));

                      return (
                        <>
                          <h4>{options.length > 0 && child.label}</h4>
                          {options}
                        </>
                      );
                    })}
                  </div>
                </div>
              </PlayFinderFilterConfigLayout>
            </SummaryTileBody>
            <PlayFinderTileFooter>
              <Button
                variant="ghost"
                shape="pill"
                size="small"
                onClick={toggleFilterOpen}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                shape="pill"
                size="small"
                onClick={() => {
                  setFilterTree(playFinderFilterTree);
                  onAddFilter(filterTree);
                }}
              >
                Apply
              </Button>
            </PlayFinderTileFooter>
          </SummaryTile>
        </PlayFinderFilterConfigWrapper>
        <PlayFinderFilterConfigBg
          $display={filterConfigOpen ? 'block' : 'none'}
          onClick={toggleFilterOpen}
        />
      </>
    );
  }
);

PlayFinderFiltersConfig.propTypes = {
  onAddFilter: PropTypes.func,
  playFinderFilterTree: PropTypes.arrayOf(PropTypes.object).isRequired,
};

PlayFinderFiltersConfig.defaultProps = {
  onAddFilter: () => {},
};

export default PlayFinderFiltersConfig;
